<template>
  <section class="brand-details">
    <v-container>
      <template v-if="brand">
        <brand-serie-product-nav :brand="brand"></brand-serie-product-nav>
        <section class="my-8">
          <brand-serie-info type="brand" :adminMode="adminMode" :item="brand"></brand-serie-info>
        </section>
        <main class="mb-8">
          <template v-if="showSeriesLoader">
            <progress-bouncing center></progress-bouncing>
          </template>
          <template v-else-if="showSeriesErrorMessage">
            <v-alert
              border="left"
              colored-border
              color="error"
              type="error"
              elevation="1"
              >{{ seriesErrorMessage }}
            </v-alert>
          </template>

          <template v-else>
            <div class="d-flex align-center mb-8">
              <b
                >{{ series.length }} serie{{
                  series.length === 1 ? "" : "r"
                }}</b
              >
              <v-spacer></v-spacer>
              <v-btn v-if="adminMode" color="primary" depressed rounded @click="addSerie()"
                ><span class="mr-1">Legg til serie</span>
                <v-icon small>mdi-plus</v-icon></v-btn
              >
            </div>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="serie in series"
                :key="serie.id"
              >
                <serie-card
                  :serie="serie"
                  :to="{ name: 'Serie', params: { id: serie.id } }"
                  hover
                  height="100%"
                  :imageOptions="{ minHeight: 120 }"
                ></serie-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" v-if="adminMode">
                <add-item-card
                  height="100%"
                  minHeight="200px"
                  hover
                  @click="addSerie()"
                >
                  Legg til serie</add-item-card
                >
              </v-col>
            </v-row>
          </template>
        </main>
      </template>
      <template v-if="showBrandLoader">
        <progress-bouncing center class="my-5"></progress-bouncing>
      </template>
      <template v-if="showBrandErrorMessage">
        <v-alert
          border="left"
          colored-border
          color="error"
          type="error"
          elevation="1"
          >{{ brandErrorMessage }}
          Tilbake til
          <router-link :to="{ name: 'Brands' }">merker og serier</router-link>
        </v-alert>
      </template>
    </v-container>
  </section>
</template>

<script>
import SerieCard from "@fixit/serie-card";
import ProgressBouncing from "@fixit/progress-bouncing";
import BrandSerieProductNav from "@/components/BrandSerieProductNav.vue";
import BrandSerieInfo from "./components/BrandSerieInfo.vue";
import AddItemCard from "@/views/brands/components/AddItemCard.vue";

export default {
  name: "BrandPage",
  components: {
    SerieCard,
    ProgressBouncing,
    BrandSerieProductNav,
    BrandSerieInfo,
    AddItemCard,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      loadingBrand: false,
      loadingSeries: false,
      brandError: null,
      seriesError: null,
    };
  },
  computed: {
    brand() {
      return this.$store.getters["brands/getBrandById"](this.id);
    },
    series() {
      return this.$store.getters["series/getSeriesByBrandId"](this.id);
    },
    showSeriesLoader() {
      return this.series.length === 0 && this.loadingSeries;
    },
    showBrandLoader() {
      return this.brand == null && this.loadingBrand;
    },

    showBrandErrorMessage() {
      return (
        this.brand == null && !this.loadingBrand && this.brandError != null
      );
    },
    showSeriesErrorMessage() {
      return !this.loadingSeries && this.seriesError != null;
    },
    brandErrorMessage() {
      if (!this.brandError) {
        return "";
      }
      const code = this.brandError?.responseCode || 0;
      if (code === 204 || code === 401 || code === 404) {
        return "Merket finnes ikke / Ingen tilgang til merket.";
      }
      return "En feil har oppstått.";
    },

    seriesErrorMessage() {
      if (!this.seriesError) {
        return "";
      }
      return "En feil har oppstått. Klarte ikke å laste serier i dette merket.";
    },
  },
  methods: {
    async fetchBrand() {
      this.loadingBrand = true;
      try {
        await this.$store.dispatch("brands/fetchBrand", this.id);
      } catch (error) {
        console.error(error);
        this.brandError = error;
      } finally {
        this.loadingBrand = false;
      }
    },
    async fetchSeries() {
      this.loadingSeries = true;
      try {
        await this.$store.dispatch("series/fetchSeries", this.id);
      } catch (error) {
        console.error(error);
        this.seriesError = error;
      } finally {
        this.loadingSeries = false;
      }
    },
    addSerie() {
      this.$router.replace({
        name: "SerieCreate",
        query: { brandId: this.id },
      });
    },
  },
  created() {
    this.fetchBrand();
    this.fetchSeries();
  },
};
</script>

<style lang="scss" scoped>
.brand-details {
  text-align: left;
  color: var(--v-primary-base);
  .container {
    max-width: 1170px;
  }
}
</style>