<template>
  <v-dialog v-if="adminMode" v-model="show" no-click-animation :persistent="loading" fullscreen>
    <v-card tile>
      <v-toolbar class="toolbar" flat dense>
        <template v-if="loading">
          <v-toolbar-title class="primary--text"
            >Lagrer merke...</v-toolbar-title
          >
        </template>
        <template v-else>
          <v-btn icon @click="close()" :disabled="loading" color="primary"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title class="primary--text">{{
            toolbarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              class="white-btn"
              depressed
              rounded
              color="primary"
              outlined
              @click="close()"
              v-if="!loading"
            >
              <span class="mx-1">Avbryt</span>
              <v-icon small color="primary" class="mr-1">mdi-close</v-icon>
            </v-btn>

            <v-btn
              depressed
              rounded
              color="primary"
              @click="confirm()"
              :disabled="btnDisabled"
              :loading="loading"
            >
              <span class="mx-1">Lagre</span>
              <v-icon small color="white" class="mr-1">mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-toolbar>
      <v-container>
        <template v-if="loading">
          <div class="d-flex align-center justify-center mt-8">
            <progress-bouncing></progress-bouncing>
          </div>
        </template>
        <template v-else>
          <v-card-text class="pt-2">
            <v-row>
              <v-col cols="12" md="5">
                <brand-serie-image
                  :image="image"
                  :imageId="imageId"
                  type="brand"
                  editBtnVisible
                  @image:uploaded="onImageUploaded($event)"
                ></brand-serie-image>
              </v-col>
              <v-col cols="12" md="7">
                <template v-if="mode === 'create'">
                  <label>ID</label>
                  <v-text-field
                    ref="firstInput"
                    filled
                    :background-color="powderBlueLight"
                    color="primary"
                    dense
                    counter
                    maxlength="128"
                    v-model.trim="dataProviderBrandIdInput"
                    :disabled="mode === 'update'"
                  ></v-text-field>
                </template>

                <label>Merkenavn</label>
                <v-text-field
                  filled
                  :background-color="powderBlueLight"
                  color="primary"
                  dense
                  counter
                  maxlength="128"
                  v-model.trim="textFieldInput"
                  :disabled="loading || !hasFetchedBrands"
                  :rules="brandNameRules"
                ></v-text-field>

                <label>Beskrivelse</label>
                <v-textarea
                  filled
                  :background-color="powderBlueLight"
                  color="primary"
                  dense
                  counter
                  maxlength="1024"
                  v-model.trim="textAreaInput"
                  :disabled="loading"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import BrandSerieImage from "./BrandSerieImage.vue";

const mode = {
  CREATE: "create",
  UPDATE: "update",
};

export default {
  name: "AddEditBrand",
  components: {
    BrandSerieImage,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      powderBlueLight: "#DFF2F4",
      dataProviderBrandIdInput: this.item?.dataProviderBrandId || null,
      textFieldInput: this.item?.name || "",
      textAreaInput: this.item?.description || "",
      base64image: null,
    };
  },
  computed: {
    adminMode(){ return (this.$store.state.authorization.userRole == 'Supplier')},
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    mode() {
      return this.item != null && this.item.id ? mode.UPDATE : mode.CREATE;
    },

    toolbarTitle() {
      return this.mode === mode.CREATE ? "Opprett merke" : "Rediger merke";
    },

    hasChanged() {
      return (
        this.item?.dataProviderBrandId !== this.dataProviderBrandIdInput ||
        this.item?.name !== this.textFieldInput ||
        this.item?.description !== this.textAreaInput
      );
    },
    btnDisabled() {
      return !this.hasChanged || !this.textFieldInput || this.brandNameExists;
    },
    brandNameRules() {
      return [
        (value) => {
          return value ? true : "Merkenavn er påkrevd";
        },
        (value) =>
          this.brandNameExists
            ? "Det eksisterer allerede et merke med navnet '" + value + "'"
            : true,
      ];
    },
    hasFetchedBrands() {
      return this.$store.state.brands.allFetched;
    },
    brandNameExists() {
      return (
        this.item?.name !== this.textFieldInput &&
        this.$store.getters["brands/brandNameExists"](this.textFieldInput)
      );
    },
    image() {
      return {
        src: this.item?.imageUrl || this.base64image || null,
        alt: this.item?.name || "",
      };
    },
    imageId() {
      return this.item?.id || 0;
    },
  },
  watch: {
    show(isOpen) {
      if (isOpen) {
        this.dataProviderBrandIdInput = this.item?.dataProviderBrandId || "";
        this.textFieldInput = this.item?.name || "";
        this.textAreaInput = this.item?.description || "";
        this.$nextTick(() => {
          const $input = this.$refs?.firstInput?.$refs?.input;
          $input && $input.focus();
        });
      }
    },
  },
  methods: {
    close() {
      if (!this.loading) {
        this.$emit("close");
      }
    },
    confirm() {
      if (!this.loading) {
        this.$emit("confirm", {
          dataProviderBrandId: this.dataProviderBrandIdInput,
          name: this.textFieldInput,
          description: this.textAreaInput,
          image: this.base64image,
        });
      }
    },
    updateBrandImage(imageUrl) {
      const currentBrand = this.$store.getters["brands/getBrandById"](
        this.item.id
      );
      this.$store.commit("brands/addOrUpdate", { ...currentBrand, imageUrl });
    },

    onImageUploaded(response) {
      if (this.mode === mode.CREATE) {
        this.base64image = response.url;
      } else if (this.mode === mode.UPDATE) {
        this.updateBrandImage(response.url);
      }
    },
  },
  created() {
    if (!this.hasFetchedBrands) {
      this.$store.dispatch("brands/fetchBrands");
    }
  },
};
</script>

<style scoped>
label {
  display: block;
  font-weight: bold;
  color: var(--v-primary-base);
  text-align: left;
  font-size: 12px;
  line-height: 1.33;
}

.toolbar {
  background: linear-gradient(90deg, #bfe5e8, #efddd1);
}
.v-toolbar__title {
  font-weight: bold;
  font-size: 16px;
}
.white-btn {
  background: white;
}
.container {
  max-width: 1170px;
}
</style>