<template>
  <section class="brands-overview">
    <v-container>
      <header class="d-flex my-8">
        <h1 class="text-h6">Våre merker</h1>
        <v-spacer></v-spacer>
        <v-btn v-if="adminMode"
          color="primary"
          depressed
          class="rounded-pill"
          @click="addBrand()"
          ><span class="mr-1">Legg til merke</span>
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
      </header>
      <main class="mb-8">
        <template v-if="showLoader">
          <progress-bouncing center></progress-bouncing>
        </template>
        <template v-else-if="showErrorMessage">
          <v-alert
            border="left"
            colored-border
            color="error"
            type="error"
            elevation="1"
            >En feil har oppstått. Klarte ikke å laste merker og serier.
          </v-alert>
        </template>

        <template v-else>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="(brand, i) in brands"
              :key="i"
            >
              <brand-card
                :brand="brand"
                :to="{ name: 'Brand', params: { id: brand.id } }"
                hover
                height="100%"
                :imageOptions="{ minHeight: 120 }"
              ></brand-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" v-if="adminMode">
              <add-item-card
                v-if="adminMode"
                height="100%"
                minHeight="200px"
                hover
                @click="addBrand()"
              >
                Legg til merke
              </add-item-card>
            </v-col>
          </v-row>
        </template>
      </main>
    </v-container>
  </section>
</template>

<script>
import ProgressBouncing from "@fixit/progress-bouncing";
import BrandCard from "@fixit/brand-card";
import { mapGetters } from "vuex";
import AddItemCard from "./components/AddItemCard.vue";
import Brand from "@/models/Brand";
import notification from "@/utils/notification";

export default {
  name: "BrandsOverview",
  components: {
    BrandCard,
    ProgressBouncing,
    AddItemCard,
  },
  data() {
    return {
      loading: false,
      error: false,
    };
  },
  computed: {
    adminMode(){ return (this.$store.state.authorization.userRole == 'Supplier')},
    ...mapGetters("brands", { brands: "getBrands" }),
    showLoader() {
      return this.brands.length === 0 && this.loading;
    },
    showErrorMessage() {
      return !this.loading && this.error;
    },
  },
  methods: {
    async fetchBrands() {
      try {
        this.loading = true;
        await this.$store.dispatch("brands/fetchBrands");
      } catch (error) {
        console.error(error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    addBrand() {
      this.$router.replace({ name: "BrandCreate" });
    },
  },
  created() {
    this.fetchBrands();
  },
};
</script>

<style lang="scss" scoped>
.brands-overview {
  text-align: left;
  color: var(--v-primary-base);
  .container {
    max-width: 1170px;
  }
}
</style>