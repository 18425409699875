<template>
  <section class="create-brand">
    <add-edit-brand
      v-model="createBrandDialog"
      :loading="savingBrand"
      @confirm="saveBrand"
      @close="navigateToBrands()"
    ></add-edit-brand>
  </section>
</template>

<script>
import AddEditBrand from "./components/AddEditBrand.vue";
import Brand from "@/models/Brand";
import notification from "@/utils/notification";

export default {
  name: "BrandCreate",
  components: {
    AddEditBrand,
  },
  data() {
    return {
      createBrandDialog: true,
      savingBrand: false,
    };
  },
  methods: {
    async saveBrand({ dataProviderBrandId, name, description, image }) {
      const brand = new Brand({
        id: 0,
        dataProviderBrandId,
        name,
        infoText: description,
      });
      try {
        this.savingBrand = true;
        let brandCreated = await this.$store.dispatch("brands/createBrand", {
          brand,
          image,
        });
        if (brandCreated) {
          notification.primary("Merke opprettet");
          this.createBrandDialog = false;
        } else {
          notification.error("Lagring av merke feilet");
        }
      } catch (error) {
        console.error(error);
        notification.error("Lagring av merke feilet");
      } finally {
        this.savingBrand = false;
      }
    },
    navigateToBrands() {
      this.$router.replace({ name: "Brands" });
    },
  },
};
</script>

<style>
</style>