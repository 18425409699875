<template>
  <section class="brand-update">
    <add-edit-brand
      v-if="brand"
      v-model="dialogOpen"
      :item="editItem"
      :loading="loading"
      @confirm="saveBrand($event)"
      @close="navigateToBrand()"
    ></add-edit-brand>
  </section>
</template>

<script>
import AddEditBrand from "@/views/brands/components/AddEditBrand.vue";
import Brand from "@/models/Brand";
import notification from "@/utils/notification";

export default {
  name: "BrandUpdate",
  components: {
    AddEditBrand,
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      dialogOpen: true,
      loading: false,
    };
  },
  computed: {
    brand() {
      return this.$store.getters["brands/getBrandById"](this.id);
    },
    editItem() {
      return {
        ...this.brand,
        description: this.brand.infoText,
      };
    },
  },
  methods: {
    navigateToBrand() {
      this.$router.replace({ name: "Brand", params: { id: this.id } });
    },
    async fetchBrand() {
      this.loading = true;
      try {
        await this.$store.dispatch("brands/fetchBrand", this.id);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async saveBrand(editedItem) {
      try {
        let result = null;
        this.loading = true;
        const brand = new Brand({
          ...this.brand,
          name: editedItem.name,
          infoTitle: "",
          infoText: editedItem.description,
        });
        result = await this.$store.dispatch("brands/updateBrand", brand);

        if (result) {
          notification.primary("Endringen av merke ble lagret");
          this.navigateToBrand();
        } else {
          notification.error("Lagring av merke feilet");
        }
      } catch (err) {
        notification.error("Lagring av merke feilet");
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (!this.brand) {
      this.fetchBrand();
    }
  },
};
</script>

<style>
</style>